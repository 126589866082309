/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import router from '@/router/index';
export default {
  data() {
    return {
      mailObject: {},
      showDetail: true,
      showReply: false,
      mailType: this.$route.params.mailType,
      mailFolderData: {}
    };
  },
  methods: {
    deleteMail() {
      var url = _baseUrl + "/mobile/oa/moveInnerMailToFolder.do";
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          target: "del",
          ids: this.mailObject.mailId,
          nowFolderId: this.$route.params.oldFolderId
        }
      }).then(response => {
        if (response.data.success) {
          this.successToast("邮件丢进垃圾箱了", 1500);
        }
        router.push({
          name: "mailBox"
        });
      }).catch(error => {
        console.log(error);
      });
    },
    destroyMail() {
      var url = _baseUrl + "/mobile/oa/delMail.do";
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          ids: this.mailObject.mailId,
          folderId: this.$route.params.oldFolderId
        }
      }).then(response => {
        if (response.data.success) {
          this.successToast("邮件已彻底删除！", 1000);
        }
        router.push({
          name: "mailBox",
          params: {
            mailType: "recycl"
          }
        });
      }).catch(error => {
        console.log(error);
      });
    },
    replyMailFunc() {
      let recUserArray = [];
      let recUser = {};
      recUser.id = this.mailObject.senderId;
      recUser.text = this.mailObject.sender;
      recUserArray.push(recUser);
      router.push({
        name: 'replyTransmitMail',
        params: {
          mailObject: this.mailObject,
          mailType: 'reply',
          recUser: JSON.stringify(recUserArray)
        }
      });
    },
    goToMailBox() {
      router.push({
        name: 'mailBox',
        params: {
          mailType: this.mailType
        }
      });
    },
    reWriteMail() {
      router.push({
        name: 'sendMail',
        params: {
          mailObject: this.mailObject
        }
      });
    }
  },
  created() {
    this.mailObject = this.$route.params.mail;
    this.mailFolderData = this.$store.state.mailModule.folderData;
  }
};