import { render, staticRenderFns } from "./InnerMailDetail.vue?vue&type=template&id=f5e58f70&scoped=true&"
import script from "./InnerMailDetail.vue?vue&type=script&lang=js&"
export * from "./InnerMailDetail.vue?vue&type=script&lang=js&"
import style0 from "./InnerMailDetail.vue?vue&type=style&index=0&id=f5e58f70&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5e58f70",
  null
  
)

export default component.exports